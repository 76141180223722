@import "colors";
.Input::-webkit-file-upload-button {
  visibility: hidden;
}

.Input {
  outline: none;
  color: transparent;
}

.Input::after {
  visibility: hidden;
}

.Input::before {
  content: "Upload Schema";
  display: inline-block;
  color: $logo-blue;
  border: 1px solid $logo-blue-light;
  border-radius: 22.5px;
  padding: 5px 15px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  line-height: 20px;
}
.Input:hover::before {
  border: 1px solid $logo-blue;
  color: $logo-blue;
  background-color: $blue-11;
}


.Input:active::before {
  background-color: $blue-10;
}
