@import "colors";
@import "utils";

button.button {
  height: $navbar-height + 1px;
  color: #c1edff;
  font-weight: 700;
  font-size: 0.6rem;
  text-transform: uppercase;
  text-align: center;
  margin-right: 18px;
  width: $sidebar-width;
  max-width: 350px;
  padding: 0 5px;
  border-bottom: 1px solid $blue-2;

  &:hover {
    color: white;
  }

  &.collapsed {
    height: $collapsed-sidebar-width;
    width: $collapsed-sidebar-width;
    font-size: 10px;
    word-break: break-all;
  }
}

.workspaceIcon {
  > svg {
    width: 25px !important;
    height: 25px !important;
    margin-left: 15px !important;
    margin-top: 5px !important;
    &:hover {
      fill: $white;
    }
  }
}

.manage {
  max-height: 450px;
  overflow: scroll;
  @extend .usable-nice-scroll;

  .title {
    margin-bottom: 10px;

    h2 {
      margin: 0 10px 0 0 !important;
      display: inline;
    }

    button {
      display: inline;
      padding: 0;
    }
  }

  div {
    &:not(:last-child) {
      margin-bottom: 5px;
    }

    max-width: 250px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  > div:not(.title) {
    padding: 2px;
  }
}

.delete {
  font-size: 11px;
  color: $text-color-light;
  padding-top: 9px;
  margin-bottom: 9px;

  button:first-of-type {
    margin-right: 3px;
  }
}
