@import "../colors";
@import "../utils";

$shrink-width: 1100px;

.calculated-fields-gallery {
  .close-button {
    display: none;
  }

  .main {
    flex: 1 0 700px;
    @media only screen and (max-width: $shrink-width) {
      flex: 1 0 480px;
    }

    .cancel-button {
      margin: 20px 0 0;
    }

    select {
      width: 100%;
      margin: 0;
    }

    .computed-field-filter {
      display: flex;
      justify-content: center;

      > * {
        flex: 1 1 0;
      }
    }

    .features {
      display: block;
      border: 1px solid $input-border-color;
      border-top: 0;
      @extend .overflow;
      height: 420px;

      li {
        cursor: pointer;
        $background-color: $focused-selected-color;
        padding: 24px 17px;
        display: block;
        border-bottom: 1px solid $input-border-color;
        font-size: 15px;

        &:hover {
          background-color: lighten($background-color, 3%);
        }

        &.selected {
          background-color: $background-color;
        }

        &:last-child {
          border-bottom: 0;
        }

        .flex-two-columns {
          align-items: center;
        }

        .primary-button {
          line-height: 32px;
          height: 32px;
          padding: 0 24px;
          font-size: 12px;
        }
      }
    }
  }

  .feature-docs {
    overflow-wrap: break-word;
    height: 604px;
    @extend .nice-scroll;
    overflow: auto;

    color: $text-color;
    font-size: 14px;

    a.external {
      float: right;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;

      span {
        margin-right: 5px;
        vertical-align: middle;
      }
    }

    p {
      max-width: 400px;
      color: $text-color;
    }

    ul {
      list-style-position: outside;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    h1,
    h2 {
      font-weight: 700;
      text-transform: uppercase;
    }

    h1 {
      font-size: 16px;
    }

    h2 {
      font-size: 13px;
    }

    table {
      margin-top: 20px;
    }
  }

  .fields-menu {
    padding-bottom: 10px;
  }
  .docs {
    text-align: center;
    padding-top: 95px;
    width: 240px;
    margin: 0 auto;

    img {
      margin-bottom: 15px;
    }

    p {
      margin-top: 10px;
      font-size: 16px;

      &:not(:last-child) {
        font-weight: bold;
      }

      strong {
        color: $logo-blue;
      }
    }
  }
}
