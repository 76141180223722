$desktop-width: 1500px;
$half-hd-width: 960px;
$tablet-width: 1024px;

$white: #ffffff;
$black: #000000;
$grey-0: #131313;
$grey-1: #212121;
$grey-2: #2a2a2a;
$grey-3: #363636;
$grey-4: #3f3f3f;
$grey-5: #555555;
$grey-6: #606060;
$grey-7: #808080;
$grey-8: #858585;
$grey-9: #9f9f9f;
$grey-10: #aaaaaa;
$grey-11: #c4c4c4;
$grey-12: #d3d3d3;
$grey-13: #e2e2e2;
$grey-14: #ededed;
$grey-15: #f3f3f3;
$grey-16: #f6f6f6;
$grey-17: #fafafa;

$green-0: #128b09;
$green-1: #17a454;
$green-2: #39b54a;
$green-3: #3ac44d;
$green-4: #5ce06c;

$logo-blue: #1cade4;
$blue-0: #0078bb;
$blue-1: #1474c9;
$blue-2: #007fb9;
$blue-3: #1079a1;
$blue-4: #178dba;
$blue-5: #008ec5;
$blue-6: #0899d0;
$blue-7: #4fb9e8;
$blue-8: #08bbff;
$blue-9: #1cc1ff;
$blue-10: #dbf3fc;
$blue-11: #e8f7fc;
$blue-12: #f1f7f9;

$logo-blue: $logo-blue;
$logo-blue-dark: $blue-3;
$logo-blue-light: $blue-9;
$link-color: $blue-8;
$focused-selected-color: $blue-11;
$selected-color: $blue-12;
$shadow-color: $grey-14;
$error-color: #f76262;
$yellow: #f2c94c;
$purple-pink: #ba55d3;

$info-color: $blue-7;
$warning-color: #f68e56;
$success-color: $green-1;
$off-white: $grey-17;
$off-white-light: $grey-13;

$input-border-color: $off-white-light;
$input-prefix-color: $blue-10;
$input-disabled-color: $grey-15;

$off-white-text: $off-white-light;
$off-white-background: $grey-16;

$collapsed-sidebar-width: 65px;
$sidebar-width: 85px;
$sidebar-item-height: $sidebar-width * 0.35;
$navbar-height: 55px;

$text-color: $grey-3;
$text-color-medium: $grey-8;
$text-color-light: $grey-11;

$background-color: $off-white;

$code-background-color: $grey-17;
$newColumnBackground: #e8f5e9;
$toDropColumnBackground: #ffebee;
