@import "./colors";

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: $logo-blue !important;
  border-radius: 50% !important;
}

.react-datepicker__time-list-item--selected {
  background-color: $logo-blue !important;
}

.react-datepicker__header {
  border-radius: 0 !important;
}

.react-datepicker__input-container {
  color: $logo-blue !important;
}

.react-datepicker__header {
  background-color: rgba(237, 237, 237, 0.54) !important;
}

//#f0f0f0a6
.time-range {
  .react-datepicker {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top: 0 !important;
    border-color: rgba(159, 159, 159, 0.64) !important;

    * {
      &:focus {
        outline: none !important;
      }
    }
  }
}

.mini-date-range-picker {
  width: 100%;
  height: 30px;

  span.icon-arrow-down {
    font-size: 10px;
    vertical-align: middle;
    padding: 5px;
    margin-left: 2px;
    color: $logo-blue;
  }

  &:hover {
    font-weight: bold;
  }

  p,
  strong {
    > * {
      font-size: 12px;
      font-weight: 500;
      margin: 0;
      text-transform: none;
    }
  }
}

.selection-options {
  display: flex;
  justify-content: space-evenly;

  ul {
    list-style: none;

    li {
      line-height: 2.02;
      font-weight: 400;
      box-sizing: border-box;
      &:hover {
        color: $logo-blue;
        font-weight: 700;
      }
    }
    .selected {
      font-weight: 700;
    }
  }
}

.clock-icon {
  margin-right: 2px;
  padding-right: 1px;
}
