@import "colors";
.Spin {
  &.Main {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .Spinner {
    animation: uspin 1s linear infinite;
    border: 2px solid $grey-11;
    border-top: 2px solid $logo-blue;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: inline-block;

    &.small {
      margin: 0 auto;
      height: 16px;
      width: 16px;
    }

    &.large {
      margin: 0 auto;
      height: 128px;
      width: 128px;
    }

    @keyframes uspin-reverse {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(-360deg);
      }
    }

    @keyframes uspin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}
