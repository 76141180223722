@import "colors";
@import "utils";
.template-title {
  max-width: 71%;
  height: $navbar-height;
  align-items: center;
  width: 200px;
  display: flex;

  .edit {
    line-height: 25px;
    opacity: 1;
    padding: 3px 5px 0;
    .icon-edit {
      @extend .clickable;
      color: $logo-blue;
      &:hover {
        color: $logo-blue-light;
      }
    }
  }

  .extra {
    line-height: 15px;
    padding-left: 2px;
    align-self: center;

    > span {
      &.edit {
        line-height: 28px;
        height: 28px;
      }
    }
  }

  .name {
    max-width: 90%;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-flex;
    font-weight: 700;

    h2 {
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @media only screen and (max-width: $half-hd-width) {
        max-width: 530px;
      }
      align-self: center;
      margin-left: 5px;
    }
  }
  @media only screen and (max-width: $desktop-width) {
    > svg:first-of-type {
      display: none;
    }
  }
  h1 {
    margin-bottom: 0;
  }
  overflow-y: hidden;
}
