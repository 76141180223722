.workspace-selector {
  height: 453px !important;

  .workspace-title {
    .icon-copy {
      margin-right: 10px;
    }
  }

  .all-workspaces, .workspace-item {
    &.active {
      box-shadow: inset 10px 0 0 0 rgba(28, 173, 228, 1);
      background-color: rgba(28, 173, 228, 0.2);
    }
  }

  .all-workspaces {
    padding-left: 1em;
  }

  .workspace-item {
    padding-left: 4em;

    .workspace-content {
      height: 100%;
      display: flex;
      flex-direction: column;

      .workspace-title {
        flex-grow: 0;
        margin-top: 10px;
      }

      .description {
        flex-grow: 1;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .workspace-item-actions {
          visibility: hidden;
        }
      }

      &:hover {
        .workspace-item-actions {
          visibility: visible;
        }
      }
    }
  }
}