@import "colors";
@import "utils";

.select-field {
  width: 875px;

  .close-button {
    display: none;
  }

  .top {
    height: 100%;
    flex: 2;
    display: flex;
    min-height: 0;

    .fields-menu {
      flex-shrink: 0;
      flex-basis: 40%;
      transition: width 0.5s ease-out;
    }

    .distribution-container {
      // This is what's left from the modal width minus fields menu.
      // This style is applied to the parents of <ResponsiveWidthContainer>
      // because we're animating their widths.
      width: 460px;
    }

    &.no-selection {
      .fields-menu {
        width: 100%;
      }
    }
  }

  > .actions {
    padding: 23px 17px 17px;
    border-top: 1px solid $shadow-color;
    margin-top: 0;
  }

  .inspections {
    .list-buttons {
      line-height: 35px;
    }

    @extend .nice-scroll;

    padding: 16px 35px 16px 19px;
    flex-grow: 1;
    overflow-y: auto;

    h1 {
      font-size: 17px;

      span.type {
        font-size: 14px;
        float: right;
        text-transform: uppercase;
      }
    }
  }

  .fields-menu {
    min-height: 0;
    border-right: 1px solid $shadow-color;

    > div {
      max-height: 100%;
      display: flex;
      flex-direction: column;

      > * {
        flex-shrink: 0;
      }

      > .overflow {
        overflow-y: hidden;
        flex: 2;
        flex-basis: auto;
        min-height: 0;
      }
    }
  }
}
