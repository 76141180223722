@import "colors";

.text-overflow {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.round-select {
  width: 250px;
  height: 45px;
  padding: 0 25px 0 20px;
  line-height: 45px;
  border-radius: 22.5px;
  background-color: $white;
  border: solid 1px $grey-11;
  margin-left: 15px;
  @media only screen and (max-width: $desktop-width) {
    width: 100px;
    margin-left: 10px;
  }
}

.shadow {
  background: $white;
  box-shadow: 0 3px 10px 0 $shadow-color;
}

.big-shadow {
  box-shadow: 0 10px 30px 0 $shadow-color;
}

.inset-big-shadow {
  box-shadow: inset 10px 0 30px -10px $shadow-color;
}

.sourceCodePro {
  font-family: "Source Code Pro Variable", monospace !important;
  font-weight: normal;
}

$scrollbar-size: 11px;

.usable-nice-scroll {
  &::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 10px;
    margin: 0;
    border: 2px solid white;
    background-color: $off-white-light;
    -webkit-border-radius: $scrollbar-size;
    height: $scrollbar-size * 3;

    &:hover {
      background-color: $grey-12;
    }
  }

  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    height: 0;
    width: 0;
    display: block;
  }
}

.click-me {
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
}

.clickable {
  @extend .click-me;
}

.force-clickable {
  @extend .click-me;
  user-select: none;
}

.nice-scroll {
  &::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
  }

  &::-webkit-scrollbar-thumb {
    margin: 0;
    border: 2px solid white;
    background-color: $off-white-light;
    -webkit-border-radius: $scrollbar-size;

    &:hover {
      background-color: $grey-12;
    }
  }

  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    height: 0;
    width: 0;
    display: block;
  }
}

.usable-nice-scroll {
  &::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
  }

  &::-webkit-scrollbar-thumb {
    margin: 0;
    border: 2px solid white;
    background-color: $off-white-light;
    -webkit-border-radius: $scrollbar-size;
    height: $scrollbar-size * 3;

    &:hover {
      background-color: $grey-12;
    }
  }

  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    height: 0;
    width: 0;
    display: block;
  }
}

.no-style-list {
  list-style: none;
}

.pull-right {
  float: right;
}

.no-width {
  width: unset;
}

.overflow {
  @extend .nice-scroll;
  overflow: auto;
}
