@import "colors";

.StepView {
  padding: 0 0 4em;

  &:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }

  &:not(:first-child) {
    padding-top: 2em;
  }

  &:last-child {
    padding-bottom: 2em;
  }

  h1 {
    color: $grey-6;
    font-size: 20px;
    text-transform: uppercase;

    display: flex;
    align-items: center;

    svg {
      margin-inline-end: .5em;
      color: $logo-blue;
    }
  }
}