@import "colors";

ul.definition-list {
  display: flex;

  > li {
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }

  &.short > li {
    margin-left: 5px;
  }

  &,
  > * {
    padding: 0;
    margin: 0;
  }

  [class*="-button"] {
    margin-left: 0;
  }

  text-transform: uppercase;
  font-weight: bold;

  > li {
    text-align: left;
    display: flex;
    flex-direction: column;

    flex-basis: auto;
    flex-shrink: 1;

    &.important {
      flex-grow: 1;
      overflow-x: visible;

      span {
        overflow-x: visible;
      }
    }

    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    overflow-x: hidden;

    > span,
    div.limited {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      font-size: 14px;
      color: $logo-blue;
      margin-top: 8px;

      .percent,
      .unit {
        font-size: 13px;
      }

      a.copy {
        color: $logo-blue;
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
    }

    > .limited {
      max-width: 50px;
      display: inline-block;
    }
  }

  &.big {
    height: 60px;

    > li {
      min-width: 80px;
      margin-right: 80px;

      > .definition-header {
        font-size: 14px;
      }

      > span {
        font-size: 26px;
      }

      .percent,
      .unit {
        font-size: 14px;
      }
    }
  }
}
