@import "colors";
$color: $blue-7;

.advanced-collapse {
  &.children {
  }

  > button {
    border: 0;
    background: 0;
    color: $color;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    margin: 10px 0;

    &:active,
    &:focus {
      outline: none;
    }

    .icon-gear {
      font-size: 16px;
      margin-right: 9px;
      vertical-align: text-bottom;
    }
  }

  > div > div {
    padding-top: 15px;
  }

  .create-feature-form form .form-input > & {
    margin-right: 0;
  }
}
