@import "colors";
@import "utils";

.multi-select-filter {
  display: flex;
  flex-direction: column;
  height: 435px;

  > *:first-child {
    margin: 0;
    flex: 0 0 auto;
  }

  > :last-child {
    flex: 1 1 0;
    border: 1px solid $input-border-color;
    border-top: 0;
  }

  > ul:last-child {
    overflow-y: scroll;
    display: block;

    > li {
      display: flex;
      height: 85px;
      border-bottom: 1px solid $input-border-color;
      align-items: center;

      &:hover {
        background-color: $selected-color;
      }

      &.focused {
        background-color: $focused-selected-color;
      }

      &.action {
        @extend .clickable;
        color: $logo-blue;
        font-size: 18px;
      }

      > input:first-child {
        margin-left: 20px;
        margin-right: 10px;
      }

      h2 {
        margin-bottom: 0;
      }

      h2 + .description {
        font-size: 11px;
        color: $text-color-light;
      }

      > :last-child {
        margin-left: 10px;
        margin-right: 20px;
      }
    }
  }
}

.flex-row,
.flex-row-block {
  flex-direction: row;
  align-items: center;

  > * {
    flex: 0 0 auto;
  }

  .flex-overflow {
    @extend .text-overflow;
  }
}

.flex-row {
  display: inline-flex;
}

.flex-row-block {
  display: flex;

  input[type="checkbox"] {
    vertical-align: middle;
  }
}

.flex-column {
  display: inline-flex;
  flex-direction: column;

  > * {
    flex: 0 0 auto;
  }
}

.flex-expand {
  flex: 1 1 0;

  .flex-row & {
    overflow-x: hidden;
  }
}
