@import "colors";
.popover {
  $border-color: $shadow-color;
  position: absolute;
  width: 250px;
  transform: translate(-90%, 12px);
  z-index: 999;
  color: $text-color-medium;

  .dropdown-button & {
    left: 36px;
    transform: translate(-103%, 22px);

    .tip {
      &:before {
        left: 100%;
        transform: translate(-30px, -15px);
      }

      &:after {
        left: 100%;
        transform: translate(-100%, -14px);
      }
    }
  }

  .tip {
    position: relative;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      border-style: solid;
      left: 50%;
      transform: translate(-50%, -15px);
      width: 0;
      height: 0;
    }

    &:before {
      border-width: 0 15px 15px 15px;
      border-color: transparent transparent $border-color transparent;
    }

    &:after {
      transform: translate(-50%, -14px);
      border-width: 0 14px 14px 14px;
      border-color: transparent transparent white transparent;
    }
  }

  .popover-content {
    box-shadow: 0 0 10px 0 $shadow-color;
    background: $white;
    border-radius: 5px;

    ul {
      text-align: left;
      font-size: 14px;
      font-weight: bold;

      text-transform: uppercase;
      display: block;

      li {
        display: block;

        a,
        .flat-button {
          text-transform: uppercase;
          padding: 0 20px;
          display: block;
          line-height: 42.5px;
          color: $grey-5;

          &:hover {
            background: $selected-color;
          }

          &[disabled] {
            opacity: 0.5;
            background: none;
            cursor: default;
            pointer-events: none;
          }
        }
      }
    }
  }
}

.popover-container {
  user-select: none;
  outline: none;
  display: inline-block;
  position: relative;

  &.button-popover {
    margin-left: 5px;

    .primary-button,
    .secondary-button {
      margin-left: 0;
    }
  }
}

.dropdown {
  outline: none;
  border: 0;

  & > .popup-container {
    position: relative;
  }

  & > .popup-container-hidden {
    visibility: hidden;
  }

  & > .popup-container > div {
    position: absolute;
    top: 0;
    left: 0;
    background: $white;
    width: 200px;
  }

  & > .popup-container-top > div {
    top: inherit;
    bottom: 0;
  }
}

.dropdown-tags {
  display: flex;
  flex-wrap: wrap;
}

.dropdown-tags input {
  flex-grow: 1;
}
