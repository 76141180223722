@import "colors";
@import "utils";
.fields-menu {
  width: 345px;
  &.full-size {
    width: 100%;
    height: 100%;
  }
  flex-shrink: 0;
  background: $white;
  z-index: 999999;
  user-select: none;
  .affix {
    position: fixed;
    top: 0;
    height: 100%;
    width: 345px;
  }

  div.filter {
    align-items: center;

    div.filter-icon {
      @extend .clickable;
      font-size: 9px;
      &.active,
      &:hover {
        color: $logo-blue;
      }
    }
  }

  button.feature {
    padding: 0 15px 0 10px;
  }

  .menu {
    padding: 0 0 0 2px;

    > .flex-two-columns {
      align-items: center;
    }

    .empty {
      font-size: 20px;
      padding: 80px 50px 0;
      line-height: 1.3em;
      text-align: center;
      text-transform: uppercase;
      color: $grey-9;

      .primary-button.small {
        font-weight: 300;
        line-height: 40px;
        height: 40px;
      }
    }

    font-size: 14px;
    color: $text-color-medium;

    line-height: 28px;

    .fields-container {
      .MuiCheckbox-root {
        padding: 0;
      }
      .hover {
        visibility: collapse;
        display: none;
      }
      &:hover {
        .hover {
          visibility: visible;
          display: inline-block;
        }
      }
      flex-grow: 1;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      &.major {
        background: $selected-color;
        color: $grey-4;
        box-shadow: inset 0px -1px 0px #d5e4ea;
      }

      &.parent {
        .MuiCheckbox-root {
          padding-right: 6px;
        }
      }

      $progress-blue: fade-out($blue-6, 0.3);
      progress {
        margin-top: 1px;
        height: 3px;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        background-color: $grey-13;
      }

      progress::-webkit-progress-bar {
        background-color: $grey-13;
      }

      progress::-webkit-progress-value {
        background-color: $progress-blue;
      }

      progress::-moz-progress-bar {
        background-color: $progress-blue;
      }
    }

    .show-hover {
      .fields-container:not(.parent) {
        .hover {
          visibility: visible;
          display: inline-block;
        }
      }
    }
    .label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      flex-shrink: 1;
      flex-grow: 1;
    }

    .field {
      display: flex;
      align-items: center;
      flex-grow: 1;

      .type {
        width: 30px;

        svg {
          fill: $text-color-medium;
          margin: 0 auto;
          display: block;
          height: 100%;
        }

        &.string {
          svg {
            padding-bottom: 1px;
          }
        }
      }

      .more {
        margin-left: 5px;
        padding-right: 3px;
        text-align: center;

        > .icon-function {
          display: inline-block;
          transform: translateY(1px);
        }
      }
    }

    .tree {
      overflow-x: hidden !important;
      display: block;

      padding-bottom: 50px;
      .tree-item {
        display: flex;
        @extend .clickable;

        .fields-container {
          &:not(.major) {
            padding-left: 10px;
          }
        }

        &.hidden {
          display: none;
        }

        .icon-arrow-down {
          display: inline-block;
          width: 10px;
          height: 10px;
          line-height: 13px;
          margin-right: 5px;
          font-size: 8px;
          transition: transform 0.25s;
        }

        &.selected-parent,
        &.selected {
          > .fields-container > :first-child {
            border-top: 1px solid $grey-15;
            background: $grey-17;
          }
        }

        &:active {
          > .fields-container > :first-child {
            background: $logo-blue;
            color: $white;
            transition: background-color 100ms ease-in-out;
            path {
              fill: $white !important;
            }
          }
        }

        &.selected > .fields-container {
          .hover {
            visibility: visible;
            display: inline-block;
          }
        }

        &.submenu {
          padding-left: 0;

          > span {
            display: flex;
            align-items: center;
          }
        }

        &.close {
          .icon-arrow-down {
            transform: rotate(-90deg);
          }

          ul,
          li {
            display: none;
          }
        }
      }

      .tree-item {
        .line {
          border-left: 1px solid $shadow-color;
          padding: 0 3px;
          margin-left: 14px;
        }
      }

      &.inputs {
        .tree-item {
          .line:first-of-type {
            border-left: unset;
            padding: unset;
            margin-left: unset;
          }
        }
      }
    }
  }

  &.narrow {
    width: unset;
    min-width: 305px;

    .tree {
      .tree-item {
        &.child {
          padding-left: 10px;
          &.submenu {
            padding-left: 10px;
          }
        }
      }
    }
  }

  &.noPadding {
    @extend .narrow;
    .tree {
      padding-bottom: 0;
    }
  }
}

.clickable-openlake-item {
  cursor: pointer;
}

.openlake-entry-label {
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%
}

.openlake-entry-icon {
  color: $grey-3;
}
