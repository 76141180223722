.TooltipContent {
  &.inline {
    display: inline;
  }
}

.SignInButton {
  margin-right: 8px;
}

.TooltipTrigger {
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
