.preview-list {
  &-line {
    display: flex;

  }
  &-execute {
    align-self: center;
    flex: 0 0 100px;
  }
  &-gap {
    width: 16px;
  }
  &-input {
    flex: 1 1;
  }
  &-toggle {
    margin-top: 1em;
    margin-bottom: .375em;
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    max-height: 15em;
    overflow-y: auto;

    .item {
      width: 250px;
      display: flex;
      align-items: center;
      margin-inline-end: 2.5em;
      line-height: 1.43em;
      cursor: default;

      span {
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #858585;
      }

      svg {
        margin-inline-end: .375em;
        color: #858585;
      }

      &.include {
        svg {
          color: #1cade4;
        }
      }
    }
  }
}
