@import "../colors";
@import "../utils";

.create-feature-form form .form-input > span.policy-editor {
  display: flex;
}

span.policy-editor {
  .borderless-button {
    margin: 0 10px 0 0;
    padding: 0;
  }

  aside {
    flex: 0 0 200px;
    padding-right: 10px;
    display: flex;
    flex-flow: column;

    li {
      border-left: 4px solid transparent;
      text-transform: capitalize;
      font-weight: normal;
      font-size: 14px;
      color: $text-color;
      line-height: 45px;
      text-align: left;

      span.icon-trash,
      .field-container > spa {
        color: $off-white-text;

        &:hover {
          color: $text-color-light;
        }
      }

      span:first-child {
        display: flex;

        > span:first-child {
          flex: 0 0 150px;
          justify-content: center;
        }

        > span:nth-child(2) {
          line-height: 45px;
          flex: 1 0;
        }
      }
    }

    li:not(:last-child) {
      border-bottom: 1px solid $shadow-color;

      &.selected {
        border-left-color: $logo-blue;
      }
    }
  }

  .next-button {
    width: 100px;
    margin-top: 15px;
  }

  article {
    min-width: 725px;
    display: flex;
    flex-grow: 1;

    > div:first-child {
      flex: 0 0 400px;
    }

    > div:nth-child(2) {
      flex: 1 0;
    }

    .code-wrapper {
      height: 100%;
      margin-left: 20px;
      padding: 15px;

      h2 {
        margin: 0 !important;
      }
    }
  }

  .react-tabs__tabs {
    width: 33%;
  }

  .fields-container {
    span.label {
      font-weight: 600;
    }

    p {
      margin: 0 0 10px 5px !important;
      padding: 0 !important;
      line-height: 25px !important;
    }
  }

  .fields-menu {
    max-height: 500px;
    overflow-y: scroll;
    @extend .nice-scroll;
    width: 100%;

    ul {
      display: block;

      li {
        > span {
          cursor: pointer;
          display: block;
        }

        display: block;
        padding-left: 15px;

        &.hidden {
          display: none;
        }

        &.selected-parent > span:first-child,
        &.selected {
          background: $logo-blue;
          color: $white;

          .type {
            color: $white;

            svg > path {
              fill: $white;
            }
          }
        }

        &.submenu {
          padding-left: 0;

          .icon-arrow-down {
            display: inline-block;
            width: 10px;
            height: 10px;
            line-height: 13px;
            margin-right: 5px;
            font-size: 8px;
            transition: transform 0.25s;
          }

          ul {
            margin-left: 5px;
            padding-left: 10px;
            border-left: 1px solid $shadow-color;
          }

          > span {
            display: flex;
            align-items: center;
          }
        }

        &.close {
          .icon-arrow-down {
            transform: rotate(-90deg);
          }

          ul,
          li {
            display: none;
          }
        }
      }
    }
  }

  .react-tabs__tab--disabled {
    color: $grey-5 !important;
  }

  ul.checkbox-input-list {
    max-height: 200px;
    overflow-y: scroll;
    @extend .nice-scroll;
  }

  ul.react-tabs__tab-list {
    line-height: 45px !important;
  }
}
