@import "colors.scss";

div.menu {
  $hover-color: $white;
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  text-align: left;
  .aliases,
  .credits,
  .users,
  .monitoring {
    stroke: $hover-color;
  }

  a,
  .menuItem {
    font-size: 13px;
    padding-left: 10px;
    line-height: 37px;

    &:hover {
      .logo > * {
        stroke: $logo-blue-light;
      }

      .aliases,
      .credits,
      .users,
      .monitoring > * {
        fill: $logo-blue-light;
      }

      color: $logo-blue;
      * {
        color: $logo-blue;
      }
    }

    > span {
      font-size: 14px;
      color: $text-color;

      > .logo {
        padding-right: 3px;
        vertical-align: text-bottom;
      }
    }

    .logOut {
      font-weight: 600;
    }
  }

  .menuTitle {
    text-transform: uppercase;
    user-select: none;
    color: $text-color;
    font-size: 14px;
    padding: 0 0 0 25px;
    line-height: 45px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .documentation {
    height: 42px;
    width: 42px;
    border-bottom: 1px solid $shadow-color;
    background: url("../upsolver-images/documentation.svg") no-repeat 0 center;
  }

  .settings {
    background: url("../upsolver-images/settings.svg") no-repeat 0 center;
  }

  .account {
    padding-left: 0;
  }
}
