@import "colors";

.react-grid-Cell.editing {
  background-color: $selected-color;
}

.row-highlight > div > div {
  background-color: $grey-12 !important;
}

.react-grid-Row {
  height: 48px;

  &:hover {
    background-color: $selected-color !important;
  }
}

.react-grid-Viewport .react-grid-Row:last-child {
  .icon-trash {
    visibility: hidden;
  }
}

.react-grid-Cell {
  &:hover {
    background-color: $selected-color !important;
  }
}

.form-input.csv .toolbar {
  position: relative;
  float: right;
  top: -30px;
  padding: 0;
  margin: 0;
}
