@import "colors";
.more-info {
  display: inline-block;
  width: 18px;
  height: 13px;
  vertical-align: middle;
  margin-right: 3px;
  opacity: 0.6;
  background: url("../upsolver-images/ellipses-normal.svg") no-repeat center center;

  &:hover,
  &.selected {
    background: url("../upsolver-images/ellipses-hover.svg") no-repeat center center;
  }
}

.field-details {
  font-size: 14px;
  color: $logo-blue;
  transition: color 0.1s;

  &:hover,
  &.selected {
    color: $logo-blue;
  }
}

.selected-field {
  color: $success-color;
}

.field-state-indicator {
  padding: 0 4px;

  &.hidden {
    opacity: 0;
    visibility: hidden;
    display: inline !important;
  }

  font-size: 0.6em;

  &::before {
    display: inline-block;
    transform: translateY(-1.5px);
  }
}
